<template>
  <div class="organisations">
    <LSection>
      <template #body>
        <div class="heading1">
          Organisations
          <hr class="gradientLine" />
        </div>
        <div
          class="flex w-full justify-between gap-8 h8:flex-row flex-col-reverse"
        >
          <div class="flex flex-col gap-8 w-full mb-24">
            <SuccessMessage title="Congratulations!"></SuccessMessage>

            <!--            {{ user?.__isAppAdmin }}-->
            <div
              v-for="document in filteredDocuments"
              :key="document.id"
              class="k1:w-full"
            >
              <Organisation
                :data-type="document.dataType"
                :organisationId="document.repoId"
              />
            </div>
          </div>
          <div class="flex-col text-center k1:sticky k1:top-24">
            <button
              v-if="isLoggedIn"
              class="button main mb-6"
              style="
                background-image: linear-gradient(to right, #db2777, #f97316);
              "
              @click="$router.push('/registerOrganisation')"
            >
              Create Organisation
            </button>

            <div
              class="p-6 h-80 bg-white rounded-lg shadow-xl flex flex-col gap-4 k1:sticky k1:top-24"
            >
              <div class="text-2xl font-bold text-start">
                Filter your results
              </div>
              <TextInput @input="filterText"></TextInput>
              <div class="flex flex-col gap-4 content-between mr-4">
                <Dropdown
                  title="Countries"
                  :items="countries"
                  @select="filterCountry"
                ></Dropdown>
                <!--              <div v-for="item in countryDropDown" :key="item.repoId">
                              {{ item }}
                            </div>-->
                <Dropdown
                  :title="'Medal'"
                  :items="['All', 'Quantum', 'Gold', 'Silver', 'Bronze']"
                  @select="filterMedal"
                ></Dropdown>
              </div>
            </div>
          </div>
        </div>
      </template>
    </LSection>
  </div>
</template>

<script>
import { ref } from "vue";
import { LIST_ASSETS } from "@/store/operations";
import { useStore } from "vuex";
import Organisation from "@/components/Organisation/Organisation.vue";
import LSection from "@/components/layout/LSection.vue";
import TextInput from "@/components/forms/TextInput.vue";
import Dropdown from "@/components/forms/Dropdown.vue";
import { useDocuments } from "@/composables/useDocuments";
import { useUserPermissions } from "@/composables/useUserPermissions";
import SuccessMessage from "@/components/forms/SuccessMessage.vue";
import { countries } from "@/components/Organisation/lib/countries";

export default {
  name: "Organisations",
  components: {
    Dropdown,
    LSection,
    Organisation,
    TextInput,
    SuccessMessage,
  },
  setup() {
    const countryDropDown = ref(null);
    const medalDropDown = ref(null);
    const dataType = "Organisation";

    const store = useStore();
    const { isLoggedIn, user } = useUserPermissions();

    const { filter, filteredDocuments } = useDocuments(dataType, {
      refresh: true,
    });

    store.dispatch(LIST_ASSETS, { dataType });

    const filterCountry = (selectedItems) => {
      countryDropDown.value = selectedItems;
      // Reset the filter value
      delete filter.value.country;

      for (const item of selectedItems) {
        if (item === "All") {
          delete filter.value.country;
          break;
        }
        filter.value.country = item;
      }
    };

    const filterMedal = (select) => {
      medalDropDown.value = select;
      delete filter.value.medal;

      for (const item of select) {
        if (item === "All") {
          delete filter.value.medal;
          return;
        }
        filter.value.medal = item;
      }
    };

    const filterText = (text) => {
      if (text === null) {
        console.log(filter.value);
        delete filter.value.name;
        return;
      }

      text = text.toLowerCase();

      filter.value = { name: text, description: text };
      console.log(filter.value);
    };

    return {
      countries,
      countryDropDown,
      filterCountry,
      filteredDocuments,
      filterMedal,
      filterText,
      medalDropDown,
      user,
      isLoggedIn,
    };
  },
};
</script>

<style scoped></style>
